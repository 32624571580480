/** ==========================================================================
 * Typography
 * --------------------------------------------------------------------------
 * Global default type styles.
 * ========================================================================== */

$fontSize-h1: $fontSize-5xl !default;
$fontSize-h2: $fontSize-4xl !default;
$fontSize-h3: $fontSize-3xl !default;
$fontSize-h4: $fontSize-xl !default;
$fontSize-h5: $fontSize-lg !default;
$fontSize-h6: $fontSize-base !default;
// $fontFamily-headings: $fontFamily-roboto !default;

/*
   Headings
   ========================================================================== */

/**
 * Classes are used here for convence sake. This should be the only place in
 * /elements that contains classes.
 */

h1, h2, h3,
h4, h5, h6,
.u-h1, .u-h2, .u-h3,
.u-h4, .u-h5, .u-h6,
.heading {
  // font-family: $fontFamily-headings;
  font-weight: $fontWeight-bold;
  // line-height: rem($lineHeight-base);
  // margin: rem($lineHeight-base) 0;
}

h1, .u-h1 {
  @include fontSize($fontSize-h1, 1.4);
  margin-top: 0;
}

h2, .u-h2 {
  @include fontSize($fontSize-h2, 1.4);
  // font-weight: $fontWeight-light;
  // line-height: lineHeight(38px, $fontSize-h2);
}

h3, .u-h3 {
  @include fontSize($fontSize-h3, 1.4);

  // This may need to be extracted to a helper class.
  // text-transform: uppercase;
  // letter-spacing: r$letterSpacing-loosest;
  @include rem(margin-bottom, $spacing-small);
}

h4, .u-h4 {
  @include fontSize($fontSize-h4, 1.4);
  // letter-spacing: $letterSpacing-looser;
  @include rem(margin-bottom, $spacing-small);
}

h5, .u-h5 {
  @include fontSize($fontSize-h5, 1.4);
  // letter-spacing: $letterSpacing-loose;
  @include rem(margin-bottom, $spacing-small);
}

h6, .u-h6 {
  @include fontSize($fontSize-h6, 1.4);
  // letter-spacing: $letterSpacing-loose;
  @include rem(margin-bottom, $spacing-small);
}

/*
   Paragraphs
   ========================================================================== */

// p {
//   // margin-bottom: 1.3em;
// }
