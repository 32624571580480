/* ==========================================================================
 * Shame
 * --------------------------------------------------------------------------
 * This is where you place your hacks and filth, if you must.
 * ========================================================================== */

/*!
 * IE10 viewport hack for Surface/desktop Windows 8 bug
 * Copyright 2014-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

/*
 * See the Getting Started docs for more information:
 * http://getbootstrap.com/getting-started/#support-ie10-width
 */
@-ms-viewport {
  width: device-width;
}

@-o-viewport {
  width: device-width;
}

@viewport {
  width: device-width;
}



// .grid-overlay:before {
//   content: "";
//   position: fixed;
//   background-color: rgba(34,102,153,0.5);
//
//   background: -webkit-linear-gradient(skyblue 2px, transparent 2px), -webkit-linear-gradient(0, skyblue 2px, transparent 2px), -webkit-linear-gradient(skyblue 1px, transparent 1px), -webkit-linear-gradient(0, skyblue 1px, transparent 1px);
//   background: -moz-linear-gradient(skyblue 2px, transparent 2px), -moz-linear-gradient(0, skyblue 2px, transparent 2px), -moz-linear-gradient(skyblue 1px, transparent 1px), -moz-linear-gradient(0, skyblue 1px, transparent 1px);
//   background: -o-linear-gradient(skyblue 2px, transparent 2px), -o-linear-gradient(0, skyblue 2px, transparent 2px), -o-linear-gradient(skyblue 1px, transparent 1px), -o-linear-gradient(0, skyblue 1px, transparent 1px);
//   background: -ms-linear-gradient(skyblue 2px, transparent 2px), -ms-linear-gradient(0, skyblue 2px, transparent 2px), -ms-linear-gradient(skyblue 1px, transparent 1px), -ms-linear-gradient(0, skyblue 1px, transparent 1px);
//   background: linear-gradient(skyblue 2px, transparent 2px), linear-gradient(0, skyblue 2px, transparent 2px), linear-gradient(skyblue 1px, transparent 1px), linear-gradient(0, skyblue 1px, transparent 1px);
//
//   -webkit-background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
//   -moz-background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
//   background-size: 50px 50px, 50px 50px, 10px 10px, 10px 10px;
//   background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
//
//   z-index: -1;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//
//   opacity: .3;
//   pointer-events: none;
// }
