/* ==========================================================================
 * Global typography variables
 * --------------------------------------------------------------------------
 * These are font/text specific variables that are used throughout the
 * project.
 * ========================================================================== */

/**
 * Font families
 */

$fontFamily-sans: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$fontFamily-serif: "Roboto Slab", Georgia, "Times New Roman", Times, serif !default;
$fontFamily-mono: "Courier New", monospace !default;
$fontFamily-roboto: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$fontFamily-openSans: $fontFamily-sans;
$fontFamily-base: $fontFamily-openSans !default;
// $fontFamily-icon:  !default;

/**
 * Font sizes
 *
 * Can't decide how to name these. Lulz.
 */

// $fontSize-xxxs: 10px !default;
// $fontSize-xxs: 12px !default;
// $fontSize-xs: 14px !default;
// $fontSize-sm: 15px !default;
// $fontSize-base: 16px !default;
// $fontSize-lg: 18px !default;
// $fontSize-xl: 22px !default;
// $fontSize-xxl: 28px !default;
// $fontSize-xxxl: 36px !default;


// $fontSize-h1: 36px !default;
// $fontSize-h2: 32px !default;
// $fontSize-h3: 28px !default;
// $fontSize-h4: 22px !default;
// $fontSize-h5: 20px !default;
// $fontSize-h6: 18px !default;


// $fontSize-micro: 10px !default;
// $fontSize-smallest: 12px !default;
// $fontSize-smaller: 14px !default;
// $fontSize-small: 15px !default;
// $fontSize-base: 16px !default;
// $fontSize-large: 18px !default;
// $fontSize-larger: 20px !default;
// $fontSize-largest: 22px !default;
// $fontSize-jumbo: 28px !default;
// $fontSize-huge: 32px !default;
// $fontSize-whoa: 36px !default;

// $fontSize-huge: 38px !default;
// $fontSize-largest: 32px !default;
// $fontSize-larger: 24px !default;
// $fontSize-large: 18px !default;
// $fontSize-base: 16px !default;
// $fontSize-small: 15px !default;
// $fontSize-smaller: 14px !default;
// $fontSize-smallest: 12px !default;
// $fontSize-micro: 10px !default;


$fontSize-7xl: 72px !default;
$fontSize-6xl: 56px !default;
$fontSize-5xl: 40px !default;
$fontSize-4xl: 32px !default;
$fontSize-3xl: 28px !default;
$fontSize-2xl: 24px !default;
$fontSize-xl: 20px !default;
$fontSize-lg: 18px !default;
$fontSize-base: 16px !default;
$fontSize-sm: 14px !default;
$fontSize-xs: 12px !default;
$fontSize-2xs: 10px !default;


$fontSize-huge: 38px !default;
$fontSize-largest: 32px !default;
$fontSize-larger: 24px !default;
$fontSize-large: 18px !default;
// $fontSize-base: 16px !default;
$fontSize-small: 15px !default;
$fontSize-smaller: 14px !default;
$fontSize-smallest: 12px !default;
$fontSize-micro: 10px !default;

/**
 * Font weights
 */

// $fontWeight-thin: 100 !default;
$fontWeight-light: 300 !default;
$fontWeight-normal: 400 !default;
$fontWeight-base: 400 !default;
$fontWeight-medium: 500 !default;
$fontWeight-semiBold: 600 !default;
$fontWeight-bold: 700 !default;
// $fontWeight-black: 900 !default;

/**
 * Letter Spacing
 */

// $letterSpacing-tightest: -1px;
// $letterSpacing-tighter: -.5px;
$letterSpacing-tight: -.01em;
$letterSpacing-normal: 0 !default;
$letterSpacing-loose: .06em !default;
// $letterSpacing-loose: .01em !default;
// $letterSpacing-looser: .03em !default;
$letterSpacing-looser: .06em !default;
// $letterSpacing-loosest: .04em !default;
$letterSpacing-loosest: .06em !default;
// $letterSpacing-loose: .6px !default;
// $letterSpacing-looser: 1.8px !default;
// $letterSpacing-loosest: 2.5px !default;

// .loose { letter-spacing: .01em; }
// .looser { letter-spacing: .03em; }
// .loosest { letter-spacing: .04em; }

// .loosePX { letter-spacing: .6px; }
// .looserPX { letter-spacing: 1.8px; }
// .loosestPX { letter-spacing: 2.5px; }
//
// .tight { letter-spacing: -.01em; }
// .tighter { letter-spacing: -.03em; }
// .tightest { letter-spacing: -.04em; }


/**
 * Line heights
 */

$lineHeight-base: 24px !default;
$lineHeight-base-num: 1.4 !default;
$lineHeight-tightest: 1.1 !default;
$lineHeight-tighter: 1.2 !default;
$lineHeight-tight: 1.3 !default;
$lineHeight-loose: 1.5 !default;
$lineHeight-looser: 1.6 !default;
$lineHeight-loosest: 1.7 !default;
