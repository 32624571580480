/* ==========================================================================
 * Ratio - Flexible media embeds
 * --------------------------------------------------------------------------
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 *
 * Example:
 *
 <div class="o-ratio  o-ratio--16x9">
   <div class="o-ratio-item">
     <!-- stuff -->
   </div>
 </div>
 * ========================================================================== */

/**
 * Generates the proper percentage for aspect ratios.
 *
 * Usage:
 * padding-bottom: getRatio(16, 9);
 */

@function getRatio($ratio-width, $ratio-height) {
  @return ($ratio-height / $ratio-width) * 100%;
}

/**
 * Default aspect ratio is 1:1.
 */

.o-ratio {
  position: relative;
  display: block;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    width: 100%;
    padding-bottom: 100%;
  }

/**
 * Fit the content to the aspect ratio
 */

  > embed,
  > iframe,
  > object,
  > video,
  > img,
  .o-ratio-item {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

}

/*
   Modifiers
   ========================================================================== */

/**
* Modifier: 2:1 aspect ratio
*/

.o-ratio--2x1:before {
 // padding-bottom: 50%;
  padding-bottom: getRatio(2, 1);
}

/**
* Modifier: 3:1 aspect ratio
*/

.o-ratio--3x1:before {
  // padding-bottom: calc(100% / 3);
  padding-bottom: getRatio(3, 1);
}

/**
* Modifier: 4:3 aspect ratio
*/

.o-ratio--4x3:before {
  // padding-bottom: 75%;
  padding-bottom: getRatio(4, 3);
}

/**
* Modifier: 16:9 aspect ratio
*/

.o-ratio--16x9:before {
  // padding-bottom: 56.25%;
  padding-bottom: getRatio(16, 9);
}
