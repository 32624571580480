/* ==========================================================================
 * #LAYOUT
 * --------------------------------------------------------------------------
 * Utility classes for low-level CSS layout traits.
 *
 * u-clearfix - Contain floats (micro clearfix).
 * u-nbfc - Create a new block formatting context.
 * u-nbfcAlt - Create a new block formatting context (alternative technique).
 * u-floatLeft - Float left.
 * u-floatRight - Float right.
 * u-floatNone - Dont Float.
 * ========================================================================== */

/**
 * Contain floats
 *
 * Make an element expand to contain floated children.
 * Uses pseudo-elements (micro clearfix).
 *
 * http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
 */

.u-clearfix {
  @include clearfix();
}

/**
 * New block formatting context
 *
 * This affords some useful properties to the element. It won't wrap under
 * floats. Will also contain any floated children.
 * N.B. This will clip overflow. Use the alternative method below if this is
 * problematic.
 */

.u-overflowHidden {
  overflow: hidden !important;
}

/**
 * New block formatting context (alternative)
 *
 * Alternative method when overflow must not be clipped.
 *
 * 1. Create a new block formatting context (NBFC).
 * 2. Avoid shrink-wrap behaviour of table-cell.
 *
 * N.B. This breaks down in some browsers when elements within this element
 * exceed its width.
 */

// .u-nbfcAlt {
//   // 1
//   display: table-cell !important;
//   // 2
//   width: 10000px !important;
// }

/**
 * Floats
 */

.u-floatLeft {
  float: left !important;
}

.u-floatRight {
  float: right !important;
}

.u-floatNone {
  float: none !important;
}

// .u-centerX {
//   margin-left: auto;
//   margin-right: auto;
// }
