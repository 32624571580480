/* ==========================================================================
 * #CURSOR
 * --------------------------------------------------------------------------
 * Utilities for changing mouse cursor.
 * ========================================================================== */

.u-cursorDefault { cursor: default !important; }
.u-cursorPointer { cursor: pointer !important; }
.u-cursorHelp { cursor: help !important; }
.u-cursorText { cursor: text !important; }
.u-cursorNope { cursor: not-allowed !important; }
