/* ==========================================================================
 * z-index
 * --------------------------------------------------------------------------
 * Global z-index settings
 * ========================================================================== */

/**
 * z-index scale
 *
 * These are the only numbers/vars you should be using for z-index values.
 */

$zIndex-1: 100 !default;
$zIndex-2: 200 !default;
$zIndex-3: 300 !default;
$zIndex-4: 400 !default;
$zIndex-5: 500 !default;
$zIndex-6: 600 !default;
$zIndex-7: 700 !default;
$zIndex-8: 800 !default;
$zIndex-9: 900 !default;
$zIndex-10: 1000 !default;
