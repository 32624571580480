/* ==========================================================================
 * #UNIT-CONVERSION
 * --------------------------------------------------------------------------
 * Mixins for converting units.
 * ========================================================================== */

/* ==========================================================================
   Em units
   ========================================================================== */

@function em($value) {
  @if (type-of($value) == number) {
    @if (unit($value) != "px") {
      @error "`#{$value}` needs to be a pixel value.";
    }
  } @else {
    @error "`#{$value}` needs to be a number.";
  }

  @return $value / $fontSize-base * 1em;
}

/* ==========================================================================
   Rem units
   ========================================================================== */

/**
 * Convert any px value into its rem equivalent.
 *
 * Usage:
 * rem($pixelValue)
 */

@function rem($value) {
  @if (type-of($value) == number) {
    @if (unit($value) != "px") {
      @error "`#{$value}` needs to be a pixel value.";
    }
  } @else {
    @error "`#{$value}` needs to be a number.";
  }
  @return $value / $fontSize-base * 1rem;
}
