/* ==========================================================================
 * #POSITION
 * --------------------------------------------------------------------------
 * Positioning utility classes.
 *
 * u-posAbsolute - Absolutely position an element.
 * u-posAbsoluteCenter - Absolutely position and center an element.
 * u-posFit - Fit an element to the dimensions of its parent
 * u-posFullScreen - Fixes an element over the viewport
 * u-posFixed - Fixed position an element.
 * u-posFixedCenter - Fix an element in the center of the viewport
 * u-posRelative - Relatively position an element.
 * u-posStatic - Static position an element.
 * ========================================================================== */

.u-posRelative { position: relative !important; }
.u-posStatic { position: static !important; }

.u-posFit,
.u-posAbsoluteCenter,
.u-posAbsolute {
  position: absolute !important;
}

/**
 * Element will be centered to its nearest relatively-positioned
 * ancestor.
 */

.u-posFixedCenter,
.u-posAbsoluteCenter {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.u-posFit,
.u-posFullScreen {
  bottom: 0 !important;
  left: 0 !important;
  margin: auto !important;
  right: 0 !important;
  top: 0 !important;
}

/**
 * 1. Make sure fixed elements are promoted into a new layer, for performance
 *    reasons.
 */

.u-posFullScreen,
.u-posFixedCenter,
.u-posFixed {
  // 1
  backface-visibility: hidden;
  position: fixed !important;
}
