/* ==========================================================================
 * #WRAPPER
 * ========================================================================== */

%wrapper {
  margin: 0 auto;
  padding: 0 calc(.5 * 20px);
  max-width: 1200px;
}


/**
 * Use the o-wrapper object to wrap elements not utilizing the grid.
 *
 * Esample:
 *
<div class="o-wrapper">
  My stuff here
</div>
 *
 */

.o-wrapper {
  @extend %wrapper;
  @include clearfix();
}
