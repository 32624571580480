/**
 * Specify the proportional offset before an object.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 4, 8, 12 section
 */

.u-push1of12 { margin-left: calc(100% * 1 / 12) !important; }
.u-push1of10 { margin-left: 10% !important; }
.u-push1of8 { margin-left: 12.5% !important; }

.u-push1of6,
.u-push2of12 { margin-left: calc(100% * 1 / 6) !important; }

.u-push1of5,
.u-push2of10 { margin-left: 20% !important; }

.u-push1of4,
.u-push2of8,
.u-push3of12 { margin-left: 25% !important; }

.u-push3of10 { margin-left: 30% !important; }

.u-push1of3,
.u-push2of6,
.u-push4of12 { margin-left: calc(100% * 1 / 3) !important; }

.u-push3of8 { margin-left: 37.5% !important; }

.u-push2of5,
.u-push4of10 { margin-left: 40% !important; }

.u-push5of12 { margin-left: calc(100% * 5 / 12) !important; }

.u-push1of2,
.u-push2of4,
.u-push3of6,
.u-push4of8,
.u-push5of10,
.u-push6of12 { margin-left: 50% !important; }

.u-push7of12 { margin-left: calc(100% * 7 / 12) !important; }

.u-push3of5,
.u-push6of10 { margin-left: 60% !important; }

.u-push5of8 { margin-left: 62.5% !important; }

.u-push2of3,
.u-push4of6,
.u-push8of12 { margin-left: calc(100% * 2 / 3) !important; }

.u-push7of10 { margin-left: 70% !important; }

.u-push3of4,
.u-push6of8,
.u-push9of12 { margin-left: 75% !important; }

.u-push4of5,
.u-push8of10 { margin-left: 80% !important; }

.u-push5of6,
.u-push10of12 { margin-left: calc(100% * 5 / 6) !important; }

.u-push7of8 { margin-left: 87.5% !important; }
.u-push9of10 { margin-left: 90% !important; }
.u-push11of12 { margin-left: calc(100% * 11 / 12) !important; }

/*
   Responsive Small Screen
   ========================================================================== */

@include breakpointUp(small) {

  .u-sm-push1of12 { margin-left: calc(100% * 1 / 12) !important; }
  .u-sm-push1of10 { margin-left: 10% !important; }
  .u-sm-push1of8 { margin-left: 12.5% !important; }

  .u-sm-push1of6,
  .u-sm-push2of12 { margin-left: calc(100% * 1 / 6) !important; }

  .u-sm-push1of5,
  .u-sm-push2of10 { margin-left: 20% !important; }

  .u-sm-push1of4,
  .u-sm-push2of8,
  .u-sm-push3of12 { margin-left: 25% !important; }

  .u-sm-push3of10 { margin-left: 30% !important; }

  .u-sm-push1of3,
  .u-sm-push2of6,
  .u-sm-push4of12 { margin-left: calc(100% * 1 / 3) !important; }

  .u-sm-push3of8 { margin-left: 37.5% !important; }

  .u-sm-push2of5,
  .u-sm-push4of10 { margin-left: 40% !important; }

  .u-sm-push5of12 { margin-left: calc(100% * 5 / 12) !important; }

  .u-sm-push1of2,
  .u-sm-push2of4,
  .u-sm-push3of6,
  .u-sm-push4of8,
  .u-sm-push5of10,
  .u-sm-push6of12 { margin-left: 50% !important; }

  .u-sm-push7of12 { margin-left: calc(100% * 7 / 12) !important; }

  .u-sm-push3of5,
  .u-sm-push6of10 { margin-left: 60% !important; }

  .u-sm-push5of8 { margin-left: 62.5% !important; }

  .u-sm-push2of3,
  .u-sm-push4of6,
  .u-sm-push8of12 { margin-left: calc(100% * 2 / 3) !important; }

  .u-sm-push7of10 { margin-left: 70% !important; }

  .u-sm-push3of4,
  .u-sm-push6of8,
  .u-sm-push9of12 { margin-left: 75% !important; }

  .u-sm-push4of5,
  .u-sm-push8of10 { margin-left: 80% !important; }

  .u-sm-push5of6,
  .u-sm-push10of12 { margin-left: calc(100% * 5 / 6) !important; }

  .u-sm-push7of8 { margin-left: 87.5% !important; }
  .u-sm-push9of10 { margin-left: 90% !important; }
  .u-sm-push11of12 { margin-left: calc(100% * 11 / 12) !important; }

}

/*
   Responsive Medium Screen
   ========================================================================== */

@include breakpointUp(medium) {

  .u-md-push1of12 { margin-left: calc(100% * 1 / 12) !important; }
  .u-md-push1of10 { margin-left: 10% !important; }
  .u-md-push1of8 { margin-left: 12.5% !important; }

  .u-md-push1of6,
  .u-md-push2of12 { margin-left: calc(100% * 1 / 6) !important; }

  .u-md-push1of5,
  .u-md-push2of10 { margin-left: 20% !important; }

  .u-md-push1of4,
  .u-md-push2of8,
  .u-md-push3of12 { margin-left: 25% !important; }

  .u-md-push3of10 { margin-left: 30% !important; }

  .u-md-push1of3,
  .u-md-push2of6,
  .u-md-push4of12 { margin-left: calc(100% * 1 / 3) !important; }

  .u-md-push3of8 { margin-left: 37.5% !important; }

  .u-md-push2of5,
  .u-md-push4of10 { margin-left: 40% !important; }

  .u-md-push5of12 { margin-left: calc(100% * 5 / 12) !important; }

  .u-md-push1of2,
  .u-md-push2of4,
  .u-md-push3of6,
  .u-md-push4of8,
  .u-md-push5of10,
  .u-md-push6of12 { margin-left: 50% !important; }

  .u-md-push7of12 { margin-left: calc(100% * 7 / 12) !important; }

  .u-md-push3of5,
  .u-md-push6of10 { margin-left: 60% !important; }

  .u-md-push5of8 { margin-left: 62.5% !important; }

  .u-md-push2of3,
  .u-md-push4of6,
  .u-md-push8of12 { margin-left: calc(100% * 2 / 3) !important; }

  .u-md-push7of10 { margin-left: 70% !important; }

  .u-md-push3of4,
  .u-md-push6of8,
  .u-md-push9of12 { margin-left: 75% !important; }

  .u-md-push4of5,
  .u-md-push8of10 { margin-left: 80% !important; }

  .u-md-push5of6,
  .u-md-push10of12 { margin-left: calc(100% * 5 / 6) !important; }

  .u-md-push7of8 { margin-left: 87.5% !important; }
  .u-md-push9of10 { margin-left: 90% !important; }
  .u-md-push11of12 { margin-left: calc(100% * 11 / 12) !important; }

}

/*
   Responsive Large Screen
   ========================================================================== */

@include breakpointUp(large) {

  .u-lg-push1of12 { margin-left: calc(100% * 1 / 12) !important; }
  .u-lg-push1of10 { margin-left: 10% !important; }
  .u-lg-push1of8 { margin-left: 12.5% !important; }

  .u-lg-push1of6,
  .u-lg-push2of12 { margin-left: calc(100% * 1 / 6) !important; }

  .u-lg-push1of5,
  .u-lg-push2of10 { margin-left: 20% !important; }

  .u-lg-push1of4,
  .u-lg-push2of8,
  .u-lg-push3of12 { margin-left: 25% !important; }

  .u-lg-push3of10 { margin-left: 30% !important; }

  .u-lg-push1of3,
  .u-lg-push2of6,
  .u-lg-push4of12 { margin-left: calc(100% * 1 / 3) !important; }

  .u-lg-push3of8 { margin-left: 37.5% !important; }

  .u-lg-push2of5,
  .u-lg-push4of10 { margin-left: 40% !important; }

  .u-lg-push5of12 { margin-left: calc(100% * 5 / 12) !important; }

  .u-lg-push1of2,
  .u-lg-push2of4,
  .u-lg-push3of6,
  .u-lg-push4of8,
  .u-lg-push5of10,
  .u-lg-push6of12 { margin-left: 50% !important; }

  .u-lg-push7of12 { margin-left: calc(100% * 7 / 12) !important; }

  .u-lg-push3of5,
  .u-lg-push6of10 { margin-left: 60% !important; }

  .u-lg-push5of8 { margin-left: 62.5% !important; }

  .u-lg-push2of3,
  .u-lg-push4of6,
  .u-lg-push8of12 { margin-left: calc(100% * 2 / 3) !important; }

  .u-lg-push7of10 { margin-left: 70% !important; }

  .u-lg-push3of4,
  .u-lg-push6of8,
  .u-lg-push9of12 { margin-left: 75% !important; }

  .u-lg-push4of5,
  .u-lg-push8of10 { margin-left: 80% !important; }

  .u-lg-push5of6,
  .u-lg-push10of12 { margin-left: calc(100% * 5 / 6) !important; }

  .u-lg-push7of8 { margin-left: 87.5% !important; }
  .u-lg-push9of10 { margin-left: 90% !important; }
  .u-lg-push11of12 { margin-left: calc(100% * 11 / 12) !important; }

}
