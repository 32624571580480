/* ==========================================================================
 * #COLORS
 * --------------------------------------------------------------------------
 * Color utilities. Use them to add/modify background and text colors.
 * ========================================================================== */

/*
   Brand Colors
   ========================================================================== */

.u-colorPrimary { color: $color-primary !important; }
.u-colorPrimaryLight { color: $color-primaryLight !important; }
.u-colorPrimaryDark { color: $color-primaryDark !important; }

.u-bgPrimary { background-color: $color-primary !important; }
.u-bgPrimaryLight { background-color: $color-primaryLight !important; }
.u-bgPrimaryDark { background-color: $color-primaryDark !important; }

.u-colorSecondary { color: $color-secondary !important; }
.u-colorSecondaryLight { color: $color-secondaryLight !important; }
.u-colorSecondaryDark { color: $color-secondaryDark !important; }

.u-bgSecondary { background-color: $color-secondary !important; }
.u-bgSecondaryLight { background-color: $color-secondaryLight !important; }
.u-bgSecondaryDark { background-color: $color-secondaryDark !important; }

.u-fillPrimary { fill: $color-primary !important; }
.u-fillPrimaryLight { fill: $color-primaryLight !important; }
.u-fillPrimaryDark { fill: $color-primaryDark !important; }

.u-fillPrimary { fill: $color-primary !important; }
.u-fillPrimaryLight { fill: $color-primaryLight !important; }
.u-fillPrimaryDark { fill: $color-primaryDark !important; }

.u-fillSecondary { fill: $color-secondary !important; }
.u-fillSecondaryLight { fill: $color-secondaryLight !important; }
.u-fillSecondaryDark { fill: $color-secondaryDark !important; }

.u-fillSecondary { fill: $color-secondary !important; }
.u-fillSecondaryLight { fill: $color-secondaryLight !important; }
.u-fillSecondaryDark { fill: $color-secondaryDark !important; }

/*
   Brand Accent Colors - Blue and Purple
   ========================================================================== */

.u-colorAccentPurple { color: $color-accent-purple !important; }
.u-bgAccentPurple { background-color: $color-accent-purple !important; }


.u-colorBlue1 { color: $color-blue1 !important; }
.u-colorBlue2 { color: $color-blue2 !important; }
.u-colorBlue3 { color: $color-blue3 !important; }
.u-colorBlue4 { color: $color-blue4 !important; }
.u-colorBlue5 { color: $color-blue5 !important; }
.u-colorBlue6 { color: $color-blue6 !important; }
.u-colorBlue7 { color: $color-blue7 !important; }
.u-colorBlue8 { color: $color-blue8 !important; }
.u-colorBlue9 { color: $color-blue9 !important; }
.u-colorBlue10 { color: $color-blue10 !important; }
.u-colorBlue11 { color: $color-blue11 !important; }

.u-bgBlue1 { background-color: $color-blue1 !important; }
.u-bgBlue2 { background-color: $color-blue2 !important; }
.u-bgBlue3 { background-color: $color-blue3 !important; }
.u-bgBlue4 { background-color: $color-blue4 !important; }
.u-bgBlue5 { background-color: $color-blue5 !important; }
.u-bgBlue6 { background-color: $color-blue6 !important; }
.u-bgBlue7 { background-color: $color-blue7 !important; }
.u-bgBlue8 { background-color: $color-blue8 !important; }
.u-bgBlue9 { background-color: $color-blue9 !important; }
.u-bgBlue10 { background-color: $color-blue10 !important; }
.u-bgBlue11 { background-color: $color-blue11 !important; }


.u-colorForest1 { color: $color-forest1 !important; }
.u-colorForest2 { color: $color-forest2 !important; }
.u-colorForest3 { color: $color-forest3 !important; }
.u-colorForest4 { color: $color-forest4 !important; }
.u-colorForest5 { color: $color-forest5 !important; }
.u-colorForest6 { color: $color-forest6 !important; }
.u-colorForest7 { color: $color-forest7 !important; }
.u-colorForest8 { color: $color-forest8 !important; }
.u-colorForest9 { color: $color-forest9 !important; }

.u-bgForest1 { background-color: $color-forest1 !important; }
.u-bgForest2 { background-color: $color-forest2 !important; }
.u-bgForest3 { background-color: $color-forest3 !important; }
.u-bgForest4 { background-color: $color-forest4 !important; }
.u-bgForest5 { background-color: $color-forest5 !important; }
.u-bgForest6 { background-color: $color-forest6 !important; }
.u-bgForest7 { background-color: $color-forest7 !important; }
.u-bgForest8 { background-color: $color-forest8 !important; }
.u-bgForest9 { background-color: $color-forest9 !important; }

.u-colorSunset1 { color: $color-sunset1 !important; }
.u-colorSunset2 { color: $color-sunset2 !important; }
.u-colorSunset3 { color: $color-sunset3 !important; }
.u-colorSunset4 { color: $color-sunset4 !important; }
.u-colorSunset5 { color: $color-sunset5 !important; }
.u-colorSunset6 { color: $color-sunset6 !important; }
.u-colorSunset7 { color: $color-sunset7 !important; }
.u-colorSunset8 { color: $color-sunset8 !important; }
.u-colorSunset9 { color: $color-sunset9 !important; }

.u-bgSunset1 { background-color: $color-sunset1 !important; }
.u-bgSunset2 { background-color: $color-sunset2 !important; }
.u-bgSunset3 { background-color: $color-sunset3 !important; }
.u-bgSunset4 { background-color: $color-sunset4 !important; }
.u-bgSunset5 { background-color: $color-sunset5 !important; }
.u-bgSunset6 { background-color: $color-sunset6 !important; }
.u-bgSunset7 { background-color: $color-sunset7 !important; }
.u-bgSunset8 { background-color: $color-sunset8 !important; }
.u-bgSunset9 { background-color: $color-sunset9 !important; }

.u-colorMulberry1 { color: $color-mulberry1 !important; }
.u-colorMulberry2 { color: $color-mulberry2 !important; }
.u-colorMulberry3 { color: $color-mulberry3 !important; }
.u-colorMulberry4 { color: $color-mulberry4 !important; }
.u-colorMulberry5 { color: $color-mulberry5 !important; }
.u-colorMulberry6 { color: $color-mulberry6 !important; }
.u-colorMulberry7 { color: $color-mulberry7 !important; }
.u-colorMulberry8 { color: $color-mulberry8 !important; }
.u-colorMulberry9 { color: $color-mulberry9 !important; }

.u-bgMulberry1 { background-color: $color-mulberry1 !important; }
.u-bgMulberry2 { background-color: $color-mulberry2 !important; }
.u-bgMulberry3 { background-color: $color-mulberry3 !important; }
.u-bgMulberry4 { background-color: $color-mulberry4 !important; }
.u-bgMulberry5 { background-color: $color-mulberry5 !important; }
.u-bgMulberry6 { background-color: $color-mulberry6 !important; }
.u-bgMulberry7 { background-color: $color-mulberry7 !important; }
.u-bgMulberry8 { background-color: $color-mulberry8 !important; }
.u-bgMulberry9 { background-color: $color-mulberry9 !important; }


/*
   Grayscale
   ========================================================================== */

.u-colorWhite { color: $color-white !important; }
.u-colorBlack { color: $color-black !important; }
.u-bgWhite, .u-hoverBgWhite { background-color: $color-white !important; }
.u-bgBlack { background-color: $color-black !important; }

.u-colorCaviar1 { color: $color-caviar1 !important; }
.u-colorCaviar2 { color: $color-caviar2 !important; }
.u-colorCaviar3 { color: $color-caviar3 !important; }
.u-colorCaviar4 { color: $color-caviar4 !important; }
.u-colorCaviar5 { color: $color-caviar5 !important; }
.u-colorCaviar6 { color: $color-caviar6 !important; }
.u-colorCaviar7 { color: $color-caviar7 !important; }
.u-colorCaviar8 { color: $color-caviar8 !important; }
.u-colorCaviar9 { color: $color-caviar9 !important; }
.u-colorCaviar10 { color: $color-caviar10 !important; }
.u-colorCaviar11 { color: $color-caviar11 !important; }

.u-bgCaviar1 { background-color: $color-caviar1 !important; }
.u-bgCaviar2 { background-color: $color-caviar2 !important; }
.u-bgCaviar3 { background-color: $color-caviar3 !important; }
.u-bgCaviar4 { background-color: $color-caviar4 !important; }
.u-bgCaviar5 { background-color: $color-caviar5 !important; }
.u-bgCaviar6 { background-color: $color-caviar6 !important; }
.u-bgCaviar7 { background-color: $color-caviar7 !important; }
.u-bgCaviar8 { background-color: $color-caviar8 !important; }
.u-bgCaviar9 { background-color: $color-caviar9 !important; }
.u-bgCaviar10 { background-color: $color-caviar10 !important; }
.u-bgCaviar11 { background-color: $color-caviar11 !important; }

.u-colorGray { color: $color-gray !important; }
.u-colorGrayLightest { color: $color-grayLightest !important; }
.u-colorGrayLighter { color: $color-grayLighter !important; }
.u-colorGrayLight { color: $color-grayLight !important; }
.u-colorGrayDark { color: $color-grayDark !important; }
.u-colorGrayDarker { color: $color-grayDarker !important; }
.u-colorGrayDarkest { color: $color-grayDarkest !important; }
.u-colorBlackish { color: $color-blackish !important; }

.u-bgGray { background-color: $color-gray !important; }
.u-bgGrayLightest { background-color: $color-grayLightest !important; }
.u-bgGrayLighter { background-color: $color-grayLighter !important; }
.u-bgGrayLight { background-color: $color-grayLight !important; }
.u-bgGrayDark { background-color: $color-grayDark !important; }
.u-bgGrayDarker { background-color: $color-grayDarker !important; }
.u-bgGrayDarkest { background-color: $color-grayDarkest !important; }
.u-bgBlackish { background-color: $color-blackish !important; }

.u-fillBlack { fill: $color-black !important; }
.u-fillWhite { fill: $color-white !important; }
.u-fillGray { fill: $color-gray !important; }
.u-fillGrayLightest { fill: $color-grayLightest !important; }
.u-fillGrayLighter { fill: $color-grayLighter !important; }
.u-fillGrayLight { fill: $color-grayLight !important; }
.u-fillGrayDark { fill: $color-grayDark !important; }
.u-fillGrayDarker { fill: $color-grayDarker !important; }
.u-fillGrayDarkest { fill: $color-grayDarkest !important; }
.u-fillBlackish { fill: $color-blackish !important; }

/*
   Social networking colors
   ========================================================================== */

// .u-colorTwitter { color: $color-twitter !important; }
// .u-colorFacebook { color: $color-facebook !important; }
// .u-colorYoutube { color: $color-youtube !important; }
// .u-colorLinkedin { color: $color-linkedin !important; }
// .u-colorInstagram { color: $color-instagram !important; }
//
// .u-bgTwitter { background-color: $color-twitter !important; }
// .u-bgFacebook { background-color: $color-facebook !important; }
// .u-bgYoutube { background-color: $color-youtube !important; }
// .u-bgLinkedin { background-color: $color-linkedin !important; }
// .u-bgInstagram { background-color: $color-instagram !important; }
//
// .u-fillTwitter { fill: $color-twitter !important; }
// .u-fillFacebook { fill: $color-facebook !important; }
// .u-fillYoutube { fill: $color-youtube !important; }
// .u-fillLinkedin { fill: $color-linkedin !important; }
// .u-fillInstagram { fill: $color-instagram !important; }

/*
   Status colors
   ========================================================================== */

.u-colorSuccess { color: $color-success !important; }
// .u-colorInfo { color: $color-info !important; }
// .u-colorWarning { color: $color-warning !important; }
.u-colorError { color: $color-error !important; }

.u-bgSuccess { background-color: $color-success !important; }
// .u-bgInfo { background-color: $color-info !important; }
// .u-bgWarning { background-color: $color-warning !important; }
.u-bgError { background-color: $color-error !important; }
