/* ==========================================================================
 * #MARGIN
 * --------------------------------------------------------------------------
 * Margin utility classes.
 *
 * ========================================================================== */

.u-m0  { margin: 0; }
.u-mt0 { margin-top: 0; }
.u-mr0 { margin-right: 0; }
.u-mb0 { margin-bottom: 0; }
.u-ml0 { margin-left: 0; }
.u-mx0 { margin-left: 0; margin-right: 0; }
.u-my0 { margin-top: 0; margin-bottom: 0; }

.u-m1  { margin: rem($spacing-smallest); }
.u-mt1 { margin-top: rem($spacing-smallest); }
.u-mr1 { margin-right: rem($spacing-smallest); }
.u-mb1 { margin-bottom: rem($spacing-smallest); }
.u-ml1 { margin-left: rem($spacing-smallest); }
.u-mx1 { margin-left: rem($spacing-smallest); margin-right: rem($spacing-smallest); }
.u-my1 { margin-top: rem($spacing-smallest); margin-bottom: rem($spacing-smallest); }

.u-m2  { margin: rem($spacing-smaller); }
.u-mt2 { margin-top: rem($spacing-smaller); }
.u-mr2 { margin-right: rem($spacing-smaller); }
.u-mb2 { margin-bottom: rem($spacing-smaller); }
.u-ml2 { margin-left: rem($spacing-smaller); }
.u-mx2 { margin-left: rem($spacing-smaller); margin-right: rem($spacing-smaller); }
.u-my2 { margin-top: rem($spacing-smaller); margin-bottom: rem($spacing-smaller); }

.u-m3  { margin: rem($spacing-small); }
.u-mt3 { margin-top: rem($spacing-small); }
.u-mr3 { margin-right: rem($spacing-small); }
.u-mb3 { margin-bottom: rem($spacing-small); }
.u-ml3 { margin-left: rem($spacing-small); }
.u-mx3 { margin-left: rem($spacing-small); margin-right: rem($spacing-small); }
.u-my3 { margin-top: rem($spacing-small); margin-bottom: rem($spacing-small); }

.u-m4  { margin: rem($spacing-base); }
.u-mt4 { margin-top: rem($spacing-base); }
.u-mr4 { margin-right: rem($spacing-base); }
.u-mb4 { margin-bottom: rem($spacing-base); }
.u-ml4 { margin-left: rem($spacing-base); }
.u-mx4 { margin-left: rem($spacing-base); margin-right: rem($spacing-base); }
.u-my4 { margin-top: rem($spacing-base); margin-bottom: rem($spacing-base); }

.u-m5  { margin: rem($spacing-large); }
.u-mt5 { margin-top: rem($spacing-large); }
.u-mr5 { margin-right: rem($spacing-large); }
.u-mb5 { margin-bottom: rem($spacing-large); }
.u-ml5 { margin-left: rem($spacing-large); }
.u-mx5 { margin-left: rem($spacing-large); margin-right: rem($spacing-large); }
.u-my5 { margin-top: rem($spacing-large); margin-bottom: rem($spacing-large); }

.u-m6  { margin: rem($spacing-larger); }
.u-mt6 { margin-top: rem($spacing-larger); }
.u-mr6 { margin-right: rem($spacing-larger); }
.u-mb6 { margin-bottom: rem($spacing-larger); }
.u-ml6 { margin-left: rem($spacing-larger); }
.u-mx6 { margin-left: rem($spacing-larger); margin-right: rem($spacing-larger); }
.u-my6 { margin-top: rem($spacing-larger); margin-bottom: rem($spacing-larger); }

.u-m7  { margin: rem($spacing-largest); }
.u-mt7 { margin-top: rem($spacing-largest); }
.u-mr7 { margin-right: rem($spacing-largest); }
.u-mb7 { margin-bottom: rem($spacing-largest); }
.u-ml7 { margin-left: rem($spacing-largest); }
.u-mx7 { margin-left: rem($spacing-largest); margin-right: rem($spacing-largest); }
.u-my7 { margin-top: rem($spacing-largest); margin-bottom: rem($spacing-largest); }

.u-m8  { margin: rem($spacing-huge); }
.u-mt8 { margin-top: rem($spacing-huge); }
.u-mr8 { margin-right: rem($spacing-huge); }
.u-mb8 { margin-bottom: rem($spacing-huge); }
.u-ml8 { margin-left: rem($spacing-huge); }
.u-mx8 { margin-left: rem($spacing-huge); margin-right: rem($spacing-huge); }
.u-my8 { margin-top: rem($spacing-huge); margin-bottom: rem($spacing-huge); }

// .u-mx1 { margin-left: -(rem($spacing-smallest)); margin-right: -(rem($spacing-smallest)); }
// .u-mx2 { margin-left: -(rem($spacing-smaller)); margin-right: -(rem($spacing-smaller)); }
// .u-mx3 { margin-left: -(rem($spacing-small)); margin-right: -(rem($spacing-small)); }
// .u-mx4 { margin-left: -(rem($spacing-base)); margin-right: -(rem($spacing-base)); }
// .u-mx5 { margin-left: -(rem($spacing-large)); margin-right: -(rem($spacing-large)); }
// .u-mx6 { margin-left: -(rem($spacing-larger)); margin-right: -(rem($spacing-larger)); }
// .u-mx7 { margin-left: -(rem($spacing-largest)); margin-right: -(rem($spacing-largest)); }

// .u-mlAuto { margin-left: auto; }
// .u-mrAuto { margin-right: auto; }
.u-mxAuto { margin-left: auto; margin-right: auto; }
.u-lastChild0 {
  > :last-child {
    margin-bottom: 0 !important;
  }
}

/* ==========================================================================
   Responsive classes
   ========================================================================== */

$responsiveMargin: true;

@if $responsiveMargin == true {

/*
   Small breakpoint
   ========================================================================== */

@include breakpointUp(small) {
  .u-sm-m0  { margin: 0; }
  .u-sm-mt0 { margin-top: 0; }
  .u-sm-mr0 { margin-right: 0; }
  .u-sm-mb0 { margin-bottom: 0; }
  .u-sm-ml0 { margin-left: 0; }
  .u-sm-mx0 { margin-left: 0; margin-right: 0; }
  .u-sm-my0 { margin-top: 0; margin-bottom: 0; }

  .u-sm-m1  { margin: rem($spacing-smallest); }
  .u-sm-mt1 { margin-top: rem($spacing-smallest); }
  .u-sm-mr1 { margin-right: rem($spacing-smallest); }
  .u-sm-mb1 { margin-bottom: rem($spacing-smallest); }
  .u-sm-ml1 { margin-left: rem($spacing-smallest); }
  .u-sm-mx1 { margin-left: rem($spacing-smallest); margin-right: rem($spacing-smallest); }
  .u-sm-my1 { margin-top: rem($spacing-smallest); margin-bottom: rem($spacing-smallest); }

  .u-sm-m2  { margin: rem($spacing-smaller); }
  .u-sm-mt2 { margin-top: rem($spacing-smaller); }
  .u-sm-mr2 { margin-right: rem($spacing-smaller); }
  .u-sm-mb2 { margin-bottom: rem($spacing-smaller); }
  .u-sm-ml2 { margin-left: rem($spacing-smaller); }
  .u-sm-mx2 { margin-left: rem($spacing-smaller); margin-right: rem($spacing-smaller); }
  .u-sm-my2 { margin-top: rem($spacing-smaller); margin-bottom: rem($spacing-smaller); }

  .u-sm-m3  { margin: rem($spacing-small); }
  .u-sm-mt3 { margin-top: rem($spacing-small); }
  .u-sm-mr3 { margin-right: rem($spacing-small); }
  .u-sm-mb3 { margin-bottom: rem($spacing-small); }
  .u-sm-ml3 { margin-left: rem($spacing-small); }
  .u-sm-mx3 { margin-left: rem($spacing-small); margin-right: rem($spacing-small); }
  .u-sm-my3 { margin-top: rem($spacing-small); margin-bottom: rem($spacing-small); }

  .u-sm-m4  { margin: rem($spacing-base); }
  .u-sm-mt4 { margin-top: rem($spacing-base); }
  .u-sm-mr4 { margin-right: rem($spacing-base); }
  .u-sm-mb4 { margin-bottom: rem($spacing-base); }
  .u-sm-ml4 { margin-left: rem($spacing-base); }
  .u-sm-mx4 { margin-left: rem($spacing-base); margin-right: rem($spacing-base); }
  .u-sm-my4 { margin-top: rem($spacing-base); margin-bottom: rem($spacing-base); }

  .u-sm-m5  { margin: rem($spacing-large); }
  .u-sm-mt5 { margin-top: rem($spacing-large); }
  .u-sm-mr5 { margin-right: rem($spacing-large); }
  .u-sm-mb5 { margin-bottom: rem($spacing-large); }
  .u-sm-ml5 { margin-left: rem($spacing-large); }
  .u-sm-mx5 { margin-left: rem($spacing-large); margin-right: rem($spacing-large); }
  .u-sm-my5 { margin-top: rem($spacing-large); margin-bottom: rem($spacing-large); }

  .u-sm-m6  { margin: rem($spacing-larger); }
  .u-sm-mt6 { margin-top: rem($spacing-larger); }
  .u-sm-mr6 { margin-right: rem($spacing-larger); }
  .u-sm-mb6 { margin-bottom: rem($spacing-larger); }
  .u-sm-ml6 { margin-left: rem($spacing-larger); }
  .u-sm-mx6 { margin-left: rem($spacing-larger); margin-right: rem($spacing-larger); }
  .u-sm-my6 { margin-top: rem($spacing-larger); margin-bottom: rem($spacing-larger); }

  .u-sm-m7  { margin: rem($spacing-largest); }
  .u-sm-mt7 { margin-top: rem($spacing-largest); }
  .u-sm-mr7 { margin-right: rem($spacing-largest); }
  .u-sm-mb7 { margin-bottom: rem($spacing-largest); }
  .u-sm-ml7 { margin-left: rem($spacing-largest); }
  .u-sm-mx7 { margin-left: rem($spacing-largest); margin-right: rem($spacing-largest); }
  .u-sm-my7 { margin-top: rem($spacing-largest); margin-bottom: rem($spacing-largest); }

  .u-sm-m8  { margin: rem($spacing-huge); }
  .u-sm-mt8 { margin-top: rem($spacing-huge); }
  .u-sm-mr8 { margin-right: rem($spacing-huge); }
  .u-sm-mb8 { margin-bottom: rem($spacing-huge); }
  .u-sm-ml8 { margin-left: rem($spacing-huge); }
  .u-sm-mx8 { margin-left: rem($spacing-huge); margin-right: rem($spacing-huge); }
  .u-sm-my8 { margin-top: rem($spacing-huge); margin-bottom: rem($spacing-huge); }

  // .u-sm-mx1 { margin-left: -(rem($spacing-smallest)); margin-right: -(rem($spacing-smallest)); }
  // .u-sm-mx2 { margin-left: -(rem($spacing-smaller)); margin-right: -(rem($spacing-smaller)); }
  // .u-sm-mx3 { margin-left: -(rem($spacing-small)); margin-right: -(rem($spacing-small)); }
  // .u-sm-mx4 { margin-left: -(rem($spacing-base)); margin-right: -(rem($spacing-base)); }
  // .u-sm-mx5 { margin-left: -(rem($spacing-large)); margin-right: -(rem($spacing-large)); }
  // .u-sm-mx6 { margin-left: -(rem($spacing-larger)); margin-right: -(rem($spacing-larger)); }
  // .u-sm-mx7 { margin-left: -(rem($spacing-largest)); margin-right: -(rem($spacing-largest)); }

  // .u-sm-mlAuto { margin-left: auto; }
  // .u-sm-mrAuto { margin-right: auto; }
  .u-sm-mxAuto { margin-left: auto; margin-right: auto; }
}

/*
   Medium breakpoint
   ========================================================================== */

@include breakpointUp(medium) {
  .u-md-m0  { margin: 0; }
  .u-md-mt0 { margin-top: 0; }
  .u-md-mr0 { margin-right: 0; }
  .u-md-mb0 { margin-bottom: 0; }
  .u-md-ml0 { margin-left: 0; }
  .u-md-mx0 { margin-left: 0; margin-right: 0; }
  .u-md-my0 { margin-top: 0; margin-bottom: 0; }

  .u-md-m1  { margin: rem($spacing-smallest); }
  .u-md-mt1 { margin-top: rem($spacing-smallest); }
  .u-md-mr1 { margin-right: rem($spacing-smallest); }
  .u-md-mb1 { margin-bottom: rem($spacing-smallest); }
  .u-md-ml1 { margin-left: rem($spacing-smallest); }
  .u-md-mx1 { margin-left: rem($spacing-smallest); margin-right: rem($spacing-smallest); }
  .u-md-my1 { margin-top: rem($spacing-smallest); margin-bottom: rem($spacing-smallest); }

  .u-md-m2  { margin: rem($spacing-smaller); }
  .u-md-mt2 { margin-top: rem($spacing-smaller); }
  .u-md-mr2 { margin-right: rem($spacing-smaller); }
  .u-md-mb2 { margin-bottom: rem($spacing-smaller); }
  .u-md-ml2 { margin-left: rem($spacing-smaller); }
  .u-md-mx2 { margin-left: rem($spacing-smaller); margin-right: rem($spacing-smaller); }
  .u-md-my2 { margin-top: rem($spacing-smaller); margin-bottom: rem($spacing-smaller); }

  .u-md-m3  { margin: rem($spacing-small); }
  .u-md-mt3 { margin-top: rem($spacing-small); }
  .u-md-mr3 { margin-right: rem($spacing-small); }
  .u-md-mb3 { margin-bottom: rem($spacing-small); }
  .u-md-ml3 { margin-left: rem($spacing-small); }
  .u-md-mx3 { margin-left: rem($spacing-small); margin-right: rem($spacing-small); }
  .u-md-my3 { margin-top: rem($spacing-small); margin-bottom: rem($spacing-small); }

  .u-md-m4  { margin: rem($spacing-base); }
  .u-md-mt4 { margin-top: rem($spacing-base); }
  .u-md-mr4 { margin-right: rem($spacing-base); }
  .u-md-mb4 { margin-bottom: rem($spacing-base); }
  .u-md-ml4 { margin-left: rem($spacing-base); }
  .u-md-mx4 { margin-left: rem($spacing-base); margin-right: rem($spacing-base); }
  .u-md-my4 { margin-top: rem($spacing-base); margin-bottom: rem($spacing-base); }

  .u-md-m5  { margin: rem($spacing-large); }
  .u-md-mt5 { margin-top: rem($spacing-large); }
  .u-md-mr5 { margin-right: rem($spacing-large); }
  .u-md-mb5 { margin-bottom: rem($spacing-large); }
  .u-md-ml5 { margin-left: rem($spacing-large); }
  .u-md-mx5 { margin-left: rem($spacing-large); margin-right: rem($spacing-large); }
  .u-md-my5 { margin-top: rem($spacing-large); margin-bottom: rem($spacing-large); }

  .u-md-m6  { margin: rem($spacing-larger); }
  .u-md-mt6 { margin-top: rem($spacing-larger); }
  .u-md-mr6 { margin-right: rem($spacing-larger); }
  .u-md-mb6 { margin-bottom: rem($spacing-larger); }
  .u-md-ml6 { margin-left: rem($spacing-larger); }
  .u-md-mx6 { margin-left: rem($spacing-larger); margin-right: rem($spacing-larger); }
  .u-md-my6 { margin-top: rem($spacing-larger); margin-bottom: rem($spacing-larger); }

  .u-md-m7  { margin: rem($spacing-largest); }
  .u-md-mt7 { margin-top: rem($spacing-largest); }
  .u-md-mr7 { margin-right: rem($spacing-largest); }
  .u-md-mb7 { margin-bottom: rem($spacing-largest); }
  .u-md-ml7 { margin-left: rem($spacing-largest); }
  .u-md-mx7 { margin-left: rem($spacing-largest); margin-right: rem($spacing-largest); }
  .u-md-my7 { margin-top: rem($spacing-largest); margin-bottom: rem($spacing-largest); }

  .u-md-m8  { margin: rem($spacing-huge); }
  .u-md-mt8 { margin-top: rem($spacing-huge); }
  .u-md-mr8 { margin-right: rem($spacing-huge); }
  .u-md-mb8 { margin-bottom: rem($spacing-huge); }
  .u-md-ml8 { margin-left: rem($spacing-huge); }
  .u-md-mx8 { margin-left: rem($spacing-huge); margin-right: rem($spacing-huge); }
  .u-md-my8 { margin-top: rem($spacing-huge); margin-bottom: rem($spacing-huge); }

  // .u-md-mx1 { margin-left: -(rem($spacing-smallest)); margin-right: -(rem($spacing-smallest)); }
  // .u-md-mx2 { margin-left: -(rem($spacing-smaller)); margin-right: -(rem($spacing-smaller)); }
  // .u-md-mx3 { margin-left: -(rem($spacing-small)); margin-right: -(rem($spacing-small)); }
  // .u-md-mx4 { margin-left: -(rem($spacing-base)); margin-right: -(rem($spacing-base)); }
  // .u-md-mx5 { margin-left: -(rem($spacing-large)); margin-right: -(rem($spacing-large)); }
  // .u-md-mx6 { margin-left: -(rem($spacing-larger)); margin-right: -(rem($spacing-larger)); }
  // .u-md-mx7 { margin-left: -(rem($spacing-largest)); margin-right: -(rem($spacing-largest)); }

  // .u-md-mlAuto { margin-left: auto; }
  // .u-md-mrAuto { margin-right: auto; }
  .u-md-mxAuto { margin-left: auto; margin-right: auto; }
}

/*
   Large breakpoint
   ========================================================================== */

@include breakpointUp(large) {
  .u-lg-m0  { margin: 0; }
  .u-lg-mt0 { margin-top: 0; }
  .u-lg-mr0 { margin-right: 0; }
  .u-lg-mb0 { margin-bottom: 0; }
  .u-lg-ml0 { margin-left: 0; }
  .u-lg-mx0 { margin-left: 0; margin-right: 0; }
  .u-lg-my0 { margin-top: 0; margin-bottom: 0; }

  .u-lg-m1  { margin: rem($spacing-smallest); }
  .u-lg-mt1 { margin-top: rem($spacing-smallest); }
  .u-lg-mr1 { margin-right: rem($spacing-smallest); }
  .u-lg-mb1 { margin-bottom: rem($spacing-smallest); }
  .u-lg-ml1 { margin-left: rem($spacing-smallest); }
  .u-lg-mx1 { margin-left: rem($spacing-smallest); margin-right: rem($spacing-smallest); }
  .u-lg-my1 { margin-top: rem($spacing-smallest); margin-bottom: rem($spacing-smallest); }

  .u-lg-m2  { margin: rem($spacing-smaller); }
  .u-lg-mt2 { margin-top: rem($spacing-smaller); }
  .u-lg-mr2 { margin-right: rem($spacing-smaller); }
  .u-lg-mb2 { margin-bottom: rem($spacing-smaller); }
  .u-lg-ml2 { margin-left: rem($spacing-smaller); }
  .u-lg-mx2 { margin-left: rem($spacing-smaller); margin-right: rem($spacing-smaller); }
  .u-lg-my2 { margin-top: rem($spacing-smaller); margin-bottom: rem($spacing-smaller); }

  .u-lg-m3  { margin: rem($spacing-small); }
  .u-lg-mt3 { margin-top: rem($spacing-small); }
  .u-lg-mr3 { margin-right: rem($spacing-small); }
  .u-lg-mb3 { margin-bottom: rem($spacing-small); }
  .u-lg-ml3 { margin-left: rem($spacing-small); }
  .u-lg-mx3 { margin-left: rem($spacing-small); margin-right: rem($spacing-small); }
  .u-lg-my3 { margin-top: rem($spacing-small); margin-bottom: rem($spacing-small); }

  .u-lg-m4  { margin: rem($spacing-base); }
  .u-lg-mt4 { margin-top: rem($spacing-base); }
  .u-lg-mr4 { margin-right: rem($spacing-base); }
  .u-lg-mb4 { margin-bottom: rem($spacing-base); }
  .u-lg-ml4 { margin-left: rem($spacing-base); }
  .u-lg-mx4 { margin-left: rem($spacing-base); margin-right: rem($spacing-base); }
  .u-lg-my4 { margin-top: rem($spacing-base); margin-bottom: rem($spacing-base); }

  .u-lg-m5  { margin: rem($spacing-large); }
  .u-lg-mt5 { margin-top: rem($spacing-large); }
  .u-lg-mr5 { margin-right: rem($spacing-large); }
  .u-lg-mb5 { margin-bottom: rem($spacing-large); }
  .u-lg-ml5 { margin-left: rem($spacing-large); }
  .u-lg-mx5 { margin-left: rem($spacing-large); margin-right: rem($spacing-large); }
  .u-lg-my5 { margin-top: rem($spacing-large); margin-bottom: rem($spacing-large); }

  .u-lg-m6  { margin: rem($spacing-larger); }
  .u-lg-mt6 { margin-top: rem($spacing-larger); }
  .u-lg-mr6 { margin-right: rem($spacing-larger); }
  .u-lg-mb6 { margin-bottom: rem($spacing-larger); }
  .u-lg-ml6 { margin-left: rem($spacing-larger); }
  .u-lg-mx6 { margin-left: rem($spacing-larger); margin-right: rem($spacing-larger); }
  .u-lg-my6 { margin-top: rem($spacing-larger); margin-bottom: rem($spacing-larger); }

  .u-lg-m7  { margin: rem($spacing-largest); }
  .u-lg-mt7 { margin-top: rem($spacing-largest); }
  .u-lg-mr7 { margin-right: rem($spacing-largest); }
  .u-lg-mb7 { margin-bottom: rem($spacing-largest); }
  .u-lg-ml7 { margin-left: rem($spacing-largest); }
  .u-lg-mx7 { margin-left: rem($spacing-largest); margin-right: rem($spacing-largest); }
  .u-lg-my7 { margin-top: rem($spacing-largest); margin-bottom: rem($spacing-largest); }

  .u-lg-m8  { margin: rem($spacing-huge); }
  .u-lg-mt8 { margin-top: rem($spacing-huge); }
  .u-lg-mr8 { margin-right: rem($spacing-huge); }
  .u-lg-mb8 { margin-bottom: rem($spacing-huge); }
  .u-lg-ml8 { margin-left: rem($spacing-huge); }
  .u-lg-mx8 { margin-left: rem($spacing-huge); margin-right: rem($spacing-huge); }
  .u-lg-my8 { margin-top: rem($spacing-huge); margin-bottom: rem($spacing-huge); }

  // .u-lg-mx1 { margin-left: -(rem($spacing-smallest)); margin-right: -(rem($spacing-smallest)); }
  // .u-lg-mx2 { margin-left: -(rem($spacing-smaller)); margin-right: -(rem($spacing-smaller)); }
  // .u-lg-mx3 { margin-left: -(rem($spacing-small)); margin-right: -(rem($spacing-small)); }
  // .u-lg-mx4 { margin-left: -(rem($spacing-base)); margin-right: -(rem($spacing-base)); }
  // .u-lg-mx5 { margin-left: -(rem($spacing-large)); margin-right: -(rem($spacing-large)); }
  // .u-lg-mx6 { margin-left: -(rem($spacing-larger)); margin-right: -(rem($spacing-larger)); }
  // .u-lg-mx7 { margin-left: -(rem($spacing-largest)); margin-right: -(rem($spacing-largest)); }

  // .u-lg-mlAuto { margin-left: auto; }
  // .u-lg-mrAuto { margin-right: auto; }
  .u-lg-mxAuto { margin-left: auto; margin-right: auto; }
}

} // end if
