/* ==========================================================================
 * Colors
 * --------------------------------------------------------------------------
 * Global color variables.
 * ========================================================================== */

/**
 * Helper Grayscale
 *
 * Use these when you ned a shade of gray that's not part of the main brand
 * grayscale.
 */

$color-white: #fff !default;
$color-black1: darken($color-white, 1%) !default;
$color-black2: darken($color-white, 2%) !default;
$color-black3: darken($color-white, 3%) !default;
$color-black4: darken($color-white, 4%) !default;
$color-black5: darken($color-white, 5%) !default;
$color-black10: darken($color-white, 10%) !default;
$color-black20: darken($color-white, 20%) !default;
$color-black30: darken($color-white, 30%) !default;
$color-black40: darken($color-white, 40%) !default;
$color-black50: darken($color-white, 50%) !default;
$color-black60: darken($color-white, 60%) !default;
$color-black70: darken($color-white, 70%) !default;
$color-black80: darken($color-white, 80%) !default;
$color-black90: darken($color-white, 90%) !default;
$color-black: #000 !default;

/**
 * Brand grayscale
 *
 * Use these as often as possible. These are mostly used as background/text
 * colors.
 */

// $color-grayLightest: #f1f2f2 !default;
// $color-grayLighter: #eee !default;
// $color-grayLight: #e6e6e6 !default;
// $color-gray: #ced1d1 !default;
// $color-grayDark: #999 !default;
// $color-grayDarker: #666 !default;
// $color-grayDarkest: #333 !default;
// $color-blackish: $color-caviar11 !default;


// $color-white: #FFFFFF !default;
// $color-black: #000000 !default;

$color-caviar1: #F7F7F7 !default;
$color-caviar2: #F0F0F0 !default;
$color-caviar3: #E6E6E8 !default;
$color-caviar4: #CDCDD0 !default;
$color-caviar5: #B4B4B9 !default;
$color-caviar6: #96969B !default;
$color-caviar7: #7D7D82 !default;
$color-caviar8: #646469 !default;
$color-caviar9: #4B4B50 !default;
$color-caviar10: #323237 !default;
$color-caviar11: #19191E !default;

$color-grayLightest: $color-caviar1 !default;
$color-grayLighter: $color-caviar2 !default;
$color-grayLight: $color-caviar3 !default;
$color-gray: $color-caviar5 !default;
$color-grayDark: $color-caviar6 !default;
$color-grayDarker: $color-caviar8 !default;
$color-grayDarkest: $color-caviar10 !default;
$color-blackish: $color-caviar11 !default;

/**
 * Colors
 */

$color-blue: #0083DB !default;
$color-blueLight: #3CABF7 !default;
$color-blueDark: #0F4896 !default;


$color-green: #53A42E !default;
$color-greenLight: #7AC458 !default;
$color-greenDark: #349528 !default;

// $color-greenAlt: #cddc39;

/**
 * Brand Colors
 */

$color-primary: $color-blue !default;
$color-primaryLight: $color-blueLight !default;
$color-primaryDark: $color-blueDark !default;

$color-secondary: $color-green !default;
$color-secondaryLight: $color-greenLight !default;
$color-secondaryDark: $color-greenDark !default;

// $color-alt: $color-greenAlt !default;
// $color-altLight: $color-greenAlt !default;
// $color-altDark: $color-greenAlt !default;

// $color-success:   #5cb85c !default;
$color-success:   #58ce8a !default;
// $color-info:      #5bc0de !default;
$color-info:      $color-blueLight !default;
$color-warning:   #eaa138 !default;
// $color-danger:    #d9534f !default;
$color-danger:    #ed4337 !default;
$color-error:    $color-danger;

$color-highlight:   rgba(255, 255, 255, .05) !default;
// $color-shadow:      rgba(0, 0, 0, .08) !default;
$color-shadow:      rgba(255, 255, 255, .1) !default;
$color-shadowInset: rgba(34, 40, 52, .7) !default;

$color-accent-purple: #BF529E; // TODO: this needs to be scrapped

// Old Blues
// $color-blue1: #FAFDFF !default;
// $color-blue2: #EFF9FF !default;
// $color-blue3: #DEF1FC !default;
// $color-blue4: #ACDBF6 !default;
// $color-blue5: #2EB9F7 !default;
// $color-blue6: #0ba3e6 !default;
// $color-blue7: #0083db !default;
// $color-blue8: #0071BD !default;
// $color-blue9: #00579c !default;
// $color-blue10: #024880 !default;
// $color-blue11: #002953 !default;

// New Jazzy Blues
$color-blue1: #F7F9FC !default;
$color-blue2: #DBF2FF !default;
$color-blue3: #B0E2FF !default;
$color-blue4: #89D3FD !default;
$color-blue5: #61C3FA !default;
$color-blue6: #3CABF7 !default;
$color-blue7: #0083DB !default;
$color-blue8: #135FBA !default;
$color-blue9: #0F4896 !default;
$color-blue10: #0B316A !default;
$color-blue11: #041E44 !default;


$color-forest1: #E3F7D7 !default;
$color-forest2: #CDF0BA !default;
$color-forest3: #ACE092 !default;
$color-forest4: #91D672 !default;
$color-forest5: #7AC458 !default;
$color-forest6: #53A42E !default;
$color-forest7: #349528 !default;
$color-forest8: #29831E !default;
$color-forest9: #206C16 !default;


$color-sunset1: #FFF9E3 !default;
$color-sunset2: #FFF3C9 !default;
$color-sunset3: #FFEDA0 !default;
$color-sunset4: #FFE471 !default;
$color-sunset5: #FFD933 !default;
$color-sunset6: #FFB91A !default;
$color-sunset7: #FF9800 !default;
$color-sunset8: #E86F00 !default;
$color-sunset9: #D04D03 !default;

$color-mulberry1: #FFEFFA !default;
$color-mulberry2: #FFE2F6 !default;
$color-mulberry3: #FABEE8 !default;
$color-mulberry4: #F29ED9 !default;
$color-mulberry5: #E17FC3 !default;
$color-mulberry6: #BF529E !default;
$color-mulberry7: #A23581 !default;
$color-mulberry8: #881465 !default;
$color-mulberry9: #650046 !default;


/**
 * Links
 */

$color-link:          $color-primary !default;
$color-linkHover:     darken($color-link, 15%) !default;

// $color-linkDark:          $color-grayDark !default;
// $color-linkDarkHover:     lighten($color-grayDark, 15%) !default;
//
// $color-linkDarker:          $color-grayDarker !default;
// $color-linkDarkerHover:     lighten($color-grayDarker, 15%) !default;
//
// $color-linkLight:          $color-grayLight !default;
// $color-linkLightHover:     darken($color-grayLight, 25%) !default;

/**
 * Scafolding
 */

$color-bodyBackground:   $color-white !default;
$color-bodyText:         $color-blackish !default;

/**
 * Text color styles
 */

$color-textWhisper:   $color-black30 !default;
$color-textQuiet:     $color-black40 !default;
$color-textLoud:      $color-black90 !default;

/**
 * Social media colors
 */

$color-twitter: #55acee;
$color-facebook: #3b5998;
$color-googleplus: #dd4b39;
// $color-pinterest: #cb2027;
$color-linkedin: #0077b5;
$color-youtube: #e62117;
// $color-vimeo: #1ab7ea;
// $color-tumblr: #32506d;
$color-instagram: #bc2a8d;
// $color-flickr: #ff0084;
