/* ==========================================================================
   #OPACITY
   ========================================================================== */

.u-opacity100 { opacity: 1;    }
.u-opacity90  { opacity: .9;   }
.u-opacity80  { opacity: .8;   }
.u-opacity70  { opacity: .7;   }
.u-opacity60  { opacity: .6;   }
.u-opacity50  { opacity: .5;   }
.u-opacity40  { opacity: .4;   }
.u-opacity30  { opacity: .3;   }
.u-opacity20  { opacity: .2;   }
.u-opacity10  { opacity: .1;   }
.u-opacity05  { opacity: .05;  }
.u-opacity025 { opacity: .025; }
.u-opacity0   { opacity: 0; }
